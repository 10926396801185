// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    firebase: {
      apiKey: "AIzaSyB38Rs0SzraPM8h6OW8yQ-lXWf9rxIUCeY",
      authDomain: "vehar-logistik-dev.firebaseapp.com",
      projectId: "vehar-logistik-dev",
      storageBucket: "vehar-logistik-dev.appspot.com",
      messagingSenderId: "300842660807",
      appId: "1:300842660807:web:97df7f3a663a05bfab1fe2"
    },
    production: false,
    appVersion: 'v8.1.8',
    USERDATA_KEY: 'authf649fc9a5f55',
    isMockEnabled: true,
    appThemeName: 'Metronic',
    appPurchaseUrl: 'https://1.envato.market/EA4JP',
    appHTMLIntegration:
      'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
    appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
    appPreviewAngularUrl:
      'https://preview.keenthemes.com/metronic8/angular/demo1',
    appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
    appPreviewChangelogUrl:
      'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
    api_url: 'https://v1-gkzbwh3kva-ey.a.run.app'
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  